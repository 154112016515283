@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');

body {
  background-color: #0d0d0d;
  color: #ffffff;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0c0c0c;
}


.navbar-brand img {
  width: 30px;
}

.nav-link:focus,
.nav-link:hover {
  color: #ffff;
}

a {
  color: #fff;
  text-decoration: none;
}

.logo-text-sizing {
  font-size: 17px;
  font-weight: 600;
}

.top-50 {
  margin-top: 40%;
}

/* / ============ DESKTOP-VIEW ============ / */

@media all and (min-width: 992px) {

  /* ===========================================CAREER-PAGE============================================== */

  .career-card-heading {
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: #fffce1;
  }

  .career-card-inner-para-css {
    font-size: 0.9rem !important;
    color: #ffffff;
    /* padding-bottom: 3em; */
    font-family: 'Heebo', sans-serif;
  }

  .border-bottom-card-row {

    border-bottom: 1px solid #fffce1;
  }

  .career-position-card-css {
    /* background-color: #1F252B; */
    /* border-radius: 1em; */
    padding: 10px 5px 10px 5px;

  }

  .glass-button-2 {
    padding: 14px 22px;
    text-decoration: none;
    border-radius: 15px 0px 15px 0px;
    background: linear-gradient(to left, #fffce1, #ffffff);
    color: rgb(0, 0, 0);
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Heebo', sans-serif;
    font-size: 0.6em !important;
  }

  .glass-button-2:hover {
    background: linear-gradient(to left, #ffffff, #fffce1);
    border-radius: 0px 15px 0px 15px;
    color: rgb(0, 0, 0);
  }


  .career-section-2 .container-section-css {
    padding: 5vw;
  }

  .career-sub-heading-css {
    font-size: 2.5em;
    /* 	margin-bottom: 2em; */
    text-transform: uppercase;
    letter-spacing: 10px;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 200;
    color: #fffce1;
  }

  .career-sub-heading-css::before {
    content: '─';
    margin-right: 0.6em;
    color: #fffce1;
  }

  .career-sub-heading-css::after {
    content: '─';
    margin-left: 0.6em;
    color: #fffce1;
  }

  .boxes {
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
  }

  .boxes:last-child {
    margin-top: 120px;
  }

  .box {
    width: 30vw;
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 200;
    padding: 0 4vw 4vw;
    border-right: 2px;
    border-left: 0;
    border-style: solid;
    border-image: linear-gradient(to bottom, #000000, #eee, #000000) 1 100%;
  }

  .box:last-child {
    border-right: 0;
  }

  .box .icon {
    font-size: 4em;
    color: #555;
    margin-bottom: 0.4em;
  }


  .box h2 {
    font-size: 1.3em;
    margin-bottom: 1em;
    text-transform: capitalize;
    letter-spacing: 4px;
    font-weight: 200;
    line-height: 1em;
    font-family: 'Playfair Display', serif;
    color: #ffffff;
  }

  .box .piece-count {
    /* letter-spacing: 1px; */
    font-size: 13px;
    color: #fffce1;
    font-family: 'Heebo', sans-serif;
  }



  .min-vh-lg-75 {
    height: 50vh;
  }

  .career-heading-position-css {
    position: relative;
    top: 25vh;
  }

  #careerPage .career-section-1 {
    /* background-image: linear-gradient(to right, #00000000, #000000be), url("../../Assests/Images/career.jpg"); */
    background-image: linear-gradient(to right, #00000000, #000000be), url("../../Assests/Images/career.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }

  .career-section-heading-css {
    font-size: 35px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
    text-transform: capitalize;
  }

  /* =======================================NAVBAR-SECTION================================================= */
  #navbarColor {
    background-color: #000000;
    height: 60px;
  }

  .navbarlinkColor {
    color: #fff;
  }

  .navbarlinkColor.default-color {
    color: #fff;
  }

  .navbarlinkColor.scrolled {
    color: #000;
    /* color: #ffffff !important; */
    /* Font color on scroll */
  }

  #navbarSection .nav-link {
    padding: 8px 20px !important;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Heebo', sans-serif;
  }



  #navbarSection {
    /* background-color: #000; */
    position: fixed;
    transition: top 0.3s;

  }

  .expandnavbar {

    background-color: #fff;
    width: 100vw;
    height: 30% !important;
  }

  .navbar-text-color {
    /* color: #fffce1 !important; */
    color: #ffffff !important;
  }

  .navbar-fixed-styling-css {
    width: 100vw;
    z-index: 999;

  }

  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {}

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    border: none;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
    width: 100vh;
    height: max-content;
    border: none;
  }

  .dropdown-item {
    font-size: 17px;
    color: #fff;
    font-weight: 500;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #fff !important;
    text-decoration: none;
    background-color: transparent !important;
  }


  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    /* background-color: #fffce1; */
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .hover-underline-animation-2 {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation-2::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation-2:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  /* =======================================FIRST-SECTION================================================= */

  .min-vh-lg-100 {
    height: 100vh;
  }

  #homebannerSection .swiper {
    width: 100%;
    height: 100%;
  }

  #homebannerSection .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
  }

  /* 
  #homebannerSection .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.5;
  } */

  .swiper-bg-image-styling-1 {
    /* background-image: linear-gradient(#00000092, #00000091), url("./Images/Banner/swiper-img-1.jpeg"); */
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-1.jpeg");

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 85vh;
    width: 100vw;
  }

  .swiper-bg-image-styling-2 {
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-2.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 85vh;
    width: 100vw;
  }

  .swiper-bg-image-styling-3 {
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-3.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 85vh;
    width: 100vw;
  }

  .banner-heading-text-css-1 {
    font-size: 60px;
    line-height: 74px;
    color: #fff;
    font-family: 'Playfair Display', serif;
  }

  .banner-heading-text-css-2 {
    font-size: 1.8rem !important;
    color: #fff;
    font-weight: 500;
  }

  /* =======================================SECOND-SECTION================================================= */

  .second-section-heading-1 {
    font-size: 35px;
    color: #fffce1;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
  }

  .second-section-heading-2 {
    font-size: 1rem !important;
    color: #ffffff;
    font-weight: 400;
  }

  .glass-section-css-styling {
    background-color: #0d0d0d;
    /* border-top-left-radius: 4em;
    border-top-right-radius: 4em; */
  }

  .glass-panel {
    /* color: #000000;
    background-color: rgb(219, 13, 13);
    border: 1px solid rgba(255, 255, 255, 0.361);
    width: 100%; */
    padding: 20px 32px 32px 32px;
    /* backdrop-filter: blur(10px); */

  }

  .glass-button {
    padding: 24px 32px;
    text-decoration: none;
    border-radius: 15px 0px 15px 0px;
    background: linear-gradient(to left, #fffce1, #ffffff);
    color: rgb(0, 0, 0);
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Heebo', sans-serif;
    font-size: 1rem !important;
  }

  .glass-button:hover {
    background: linear-gradient(to left, #ffffff, #fffce1);
    border-radius: 0px 15px 0px 15px;
    box-shadow: none;
    outline: none;

  }



  /* =======================================THIRD-SECTION================================================= */

  .third-section-title-text-css-1 {
    font-size: 35px;
    color: #fffce1;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
  }

  /* .border-bottom-black {
    border-bottom: 0.2em solid #fffce1;
  } */

  .third-section-heading-1 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  .card-image-css-styling {
    border-radius: 0.8em;
    width: 400px;
    height: 300px;
  }

  /* =======================================FOURTH-SECTION================================================= */
  .marquee-full-bg {
    background: #000000;
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to left, rgb(0, 60, 112), hsl(234, 100%, 8%)); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to left, #000749, #000428); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background-color: #33383e; */
    background: linear-gradient(to left, #000000, #000000);
  }

  .marquee-card-styling {
    width: 350px;
    text-align: center;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 0.3em;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    mask-image: linear-gradient(135deg, #000 0, #000 22%, rgba(0, 0, 0, 0) 160%);
    -webkit-mask-image: linear-gradient(135deg, #000 0, #000 22%, rgba(0, 0, 0, 0) 160%);
    padding: 15px;
    margin: 0px 10px 0px 10px;
  }

  .marquee-section-heading-1 {
    font-size: 30px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
  }

  .tech-icon-css-styling {
    width: 100px;
    height: 100px;
  }

  /* =======================================FIFTH-SECTION================================================= */
  .curve-card-heading-1 {
    font-size: 15px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  .curve-card-para-1 {
    font-size: 12px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;

  }

  .portfolioDisc {
    width: 100%;
    height: 0px;
    padding-top: 0px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top: 300px;
    position: absolute;
    font-size: 15px;
    /* line-height: 18px; */
    text-align: center;
    font-family: 'Heebo', sans-serif;
    color: #ffffff;
    align-items: center !important;

  }


  .port-1 {
    background-image: url("../../Assests/Images/Services/construction.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-2 {
    background-image: url("../../Assests/Images/Services/Healthcare.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-3 {
    background-image: url("../../Assests/Images/Services/Bookingticket.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-4 {
    background-image: url("../../Assests/Images/Services/Hotel.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-5 {
    background-image: url("../../Assests/Images/Services/GPStracking.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 500px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-1:hover,
  .port-2:hover,
  .port-3:hover,
  .port-4:hover,
  .port-5:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
    overflow: hidden !important;
    cursor: pointer;
  }

  .port-1:hover .portfolioDisc {
    margin-top: 0px;
    height: 500px;
    padding-top: 70%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-2:hover .portfolioDisc {
    margin-top: 0px;
    height: 500px;
    padding-top: 70%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-3:hover .portfolioDisc {
    margin-top: 0px;
    height: 500px;
    padding-top: 70%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-4:hover .portfolioDisc {
    margin-top: 0px;
    height: 500px;
    padding-top: 70%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-5:hover .portfolioDisc {
    margin-top: 0px;
    height: 500px;
    padding-top: 70%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .clippath-css-styling {
    /* clip-path: polygon(85% 9%, 72% 12%, 35% 13%, 19% 9%, 0% 0%, 0% 46%, 0% 100%, 22% 88%, 28% 87%, 73% 87%, 80% 89%, 100% 100%, 100% 48%, 100% 0%);
     */
    clip-path: url("#myCurve");
    overflow: hidden !important;

  }

  .clippath-css-styling-2 {
    clip-path: url("#myCurvetwo");
  }


  /* =======================================SIXTH-SECTION================================================= */


  /* #tabsSection {
    background-image: linear-gradient(#ffff 45%, #1F252B 10%);
  } */

  .line-height-tabs {
    z-index: 9999 !important;

  }

  #tabsSection .tab-content {
    position: relative;
    top: -45px;
    background-color: #000000;
    z-index: -999;
  }

  .sixth-section-text-css-2 {
    font-size: 1.3rem !important;
    color: #ffffff;
    font-weight: 500;
  }

  #tabsSection .nav-pills .nav-link.active,
  #tabsSection .nav-pills .show>.nav-link {
    color: #fffce1;
    background-color: transparent;
  }

  .tabs-inner-heading-css {
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: rgb(255, 255, 255);
  }

  .tabs-inner-para-css {
    font-size: 0.9rem !important;
    color: #ffffff;
    padding-bottom: 3em;
    /* font-family: 'Heebo', sans-serif; */

  }

  .tabs-heading-css {
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: rgb(111, 111, 111);
    height: 120px;
  }

  #tabsSection .nav-pills .nav-link.active .tabs-heading-css {
    color: #fffce1;
  }

  .tabs-icon-div-styling-css {
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 2em;
    border: 3px solid #fff;
    width: 100px;
    height: 100px;
  }

  .tabs-icon-styling-css {
    font-size: 2em;
    color: #fffce1;

  }

  #tabsSection .nav-pills .nav-link.active .tabs-icon-div-styling-css {
    background-color: #ffffff;
    border: 4px solid #9c9c9c;
    border-radius: 50%;
    padding: 2em;
  }

  #tabsSection .nav-pills .nav-link.active .tabs-icon-styling-css {
    font-size: 2em;
    color: #000000;
  }

  .tabs-content-ul li {
    color: #ffff;
    list-style: none;
    margin-bottom: 10px;
  }

  .tabs-content-ul li::before {
    content: "\2B9E";
    margin-right: 6px;
  }

  .tabs-content-image-css {
    width: 50%;
    height: 50%s;
  }

  /* =======================================OUR-TEAM-SECTION================================================= */


  #ourteam_testing .modal-content {
    background-color: #2E2E2E;
    color: #ffffff;
  }

  #ourteam_testing .our-team-card-image-css-styling {
    width: 80%;
    height: 150px;
  }

  #ourteam_testing .our-team-section-heading-1 {
    font-size: 26px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;

  }

  #ourteam_testing .our-team-section-heading-2 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  #ourteam_testing .inner-card-design-css {
    background-color: #373636;
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%); */
  }


  #ourteam_testing .inner-card-design-css-2 {
    background-color: #000000;
    border: 0.5em double #373636;
  }

  /* =======================================FOOTER-SECTION================================================= */
  #pricingModal .modal-content {
    background-color: #2E2E2E;
    color: #ffffff;
  }

  #pricingModal .footer-card-image-css-styling {
    width: 80%;
    height: 150px;
  }

  #pricingModal .footer-card-section-heading-1 {
    font-size: 26px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;

  }

  #pricingModal .footer-card-section-heading-2 {
    font-size: 25px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  #pricingModal .inner-card-design-css {
    background-color: #0d0d0d;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  }


  #pricingModal .inner-card-design-css-2 {
    background-color: #373636;
  }

  #pricingModal .footer-heading-height-css {
    height: 100px;

  }

  ul {
    margin: 0px;
    padding: 0px;
  }

  .footer-section {
    background: linear-gradient(to left, #000000, #000000);
    color: #ffffff;
    position: relative;
  }

  .footer-cta {
    border-top: 1px solid #373636;
  }

  .single-cta i {
    color: #ff5e14;
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }

  .cta-text {
    padding-left: 15px;
    display: inline-block;
  }

  .cta-text h4 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  .cta-text span {
    color: #ffffff;
    font-size: 15px;
  }

  .footer-content {
    position: relative;
    z-index: 2;
  }

  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }

  .footer-logo {
    margin-bottom: 30px;
  }

  .footer-logo img {
    max-width: 50px;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #ffffff;
    line-height: 28px;
  }

  .footer-social-icon span {
    color: #ffffff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  .footer-social-icon a {
    color: #ffffff;
    font-size: 16px;
    margin-right: 15px;
  }

  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }

  .facebook-bg {
    background: #3B5998;
  }

  .twitter-bg {
    background: #55ACEE;
  }

  .google-bg {
    background: #DD4B39;
  }

  .footer-widget-heading h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }

  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ff5e14;
  }

  .footer-widget ul li {
    /* display: inline-block; */
    /* float: left; */
    width: 100%;
    margin-bottom: 12px;
    list-style: none;
  }

  .footer-widget ul li a:hover {
    color: #ff5e14;
  }

  .footer-widget ul li a {
    color: #ffffff;
    text-transform: capitalize;
  }

  /* .subscribe-form {
    position: relative;
    overflow: hidden;
  } */

  .subscribe-form .form-control {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
    /* margin-bottom: 0.5em; */
  }

  .subscribe-form .form-control:focus {
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
    outline: none;
    box-shadow: none;
  }

  /* .subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
  }

  .subscribe-form button i {
    color: #ffffff;
    font-size: 22px;
    transform: rotate(-6deg);
  } */

  .copyright-area {
    border-top: 1px solid #373636;
    background: linear-gradient(to left, #0d0d0d, #0d0d0d);

    /* background: #202020; */
    padding: 25px 0;
  }

  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }

  .copyright-text p a {
    color: #ff5e14;
  }

  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }

  .footer-menu li:hover a {
    color: #ff5e14;
  }

  .footer-menu li a {
    font-size: 14px;
    color: #ffffff;
  }

}

/* / ============ MOBILE-VIEW ============ / */

@media(max-width: 991px) {
  /* ===========================================CAREER-PAGE============================================== */

  .career-card-heading {
    font-size: 1.5em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: #fffce1;
  }

  .career-card-inner-para-css {
    font-size: 0.9rem !important;
    color: #ffffff;
    /* padding-bottom: 3em; */
    font-family: 'Heebo', sans-serif;
  }

  .border-bottom-card-row {

    border-bottom: 1px solid #fffce1;
  }

  .career-position-card-css {
    /* background-color: #1F252B; */
    /* border-radius: 1em; */
    padding: 10px 5px 10px 5px;

  }

  .glass-button-2 {
    padding: 14px 22px;
    text-decoration: none;
    border-radius: 15px 0px 15px 0px;
    background: linear-gradient(to left, #fffce1, #ffffff);
    color: rgb(0, 0, 0);
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Heebo', sans-serif;
    font-size: 0.6em !important;
  }

  .glass-button-2:hover {
    background: linear-gradient(to left, #ffffff, #fffce1);
    border-radius: 0px 15px 0px 15px;
    color: rgb(0, 0, 0);
  }


  .career-section-2 .container-section-css {
    padding: 5vw;
  }

  .career-sub-heading-css {
    font-size: 1em;
    /* 	margin-bottom: 2em; */
    text-transform: uppercase;
    letter-spacing: 5px;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-weight: 200;
    color: #fffce1;
  }

  .career-sub-heading-css::before {
    content: '─';
    margin-right: 0.6em;
    color: #fffce1;
  }

  .career-sub-heading-css::after {
    content: '─';
    margin-left: 0.6em;
    color: #fffce1;
  }

  .boxes {
    margin-top: 50px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .boxes:last-child {
    margin-top: 120px;
  }

  .box {
    /* width: 30vw; */
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 200;
    padding: 0 4vw 4vw;
    /* border-right: 2px;
      border-left: 0;
      border-style: solid; */
    /* border-image: linear-gradient(to bottom, #000000, #eee, #000000) 1 100%; */
  }

  .box:last-child {
    border-right: 0;
  }

  .box .icon {
    font-size: 4em;
    color: #555;
    margin-bottom: 0.4em;
  }


  .box h2 {
    font-size: 1.3em;
    margin-bottom: 1em;
    text-transform: capitalize;
    letter-spacing: 4px;
    font-weight: 200;
    line-height: 1em;
    font-family: 'Playfair Display', serif;
    color: #ffffff;
  }

  .box .piece-count {
    /* letter-spacing: 1px; */
    font-size: 13px;
    color: #fffce1;
    font-family: 'Heebo', sans-serif;
  }



  .min-vh-lg-75 {
    height: 50vh;
  }

  /* .career-heading-position-css {
      position: relative;
      top: 25vh;
    } */

  #careerPage .career-section-1 {
    /* background-image: linear-gradient(to right, #00000000, #000000be), url("./Images/career.jpg"); */
    background-image: linear-gradient(to right, #00000000, #000000be), url("../../Assests/Images/career.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

  }

  .career-section-heading-css {
    font-size: 35px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
    text-transform: capitalize;
  }

  /* =======================================NAVBAR-SECTION================================================= */
  #navbarColor {
    background-color: #000000;
  }

  .navbarlinkColor {
    color: #000000;
  }

  .navbarlinkColor.default-color {
    color: #000000;
  }

  .navbarlinkColor.scrolled {
    color: #000;
    /* Font color on scroll */
  }

  #navbarSection .nav-link {
    padding: 8px 20px !important;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Heebo', sans-serif;
  }

  #navbarSection {
    background-color: #000;
    position: fixed;
    transition: top 0.3s;
  }

  .expandnavbar {

    background-color: #fff;
    width: 100vw;
    height: 30% !important;
  }

  .navbar-text-color {
    color: #fffce1 !important;
  }

  .navbar-fixed-styling-css {
    width: 100vw;
    z-index: 999;

  }

  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {}

  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    border: none;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
    width: 100vh;
    height: max-content;
    border: none;
  }

  .dropdown-item {
    font-size: 17px;
    color: #000;
    font-weight: 500;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #755392 !important;
    text-decoration: none;
    background-color: transparent !important;
  }


  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    /* background-color: #fffce1; */
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .hover-underline-animation-2 {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation-2::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation-2:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  /* =======================================FIRST-SECTION================================================= */


  #homebannerSection .swiper {
    width: 100%;
    height: 50vh;
  }

  #homebannerSection .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 50vh;
  }

  /* 
    #homebannerSection .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    } */

  .swiper-bg-image-styling-1 {
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-1.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 50vh;
    width: 100vw;
  }

  .swiper-bg-image-styling-2 {
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-2.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 50vh;
    width: 100vw;
  }

  .swiper-bg-image-styling-3 {
    background-image: linear-gradient(#00000092, #00000091), url("../../Assests/Images/Banner/swiper-img-3.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-bottom-left-radius: 3em;
    border-bottom-right-radius: 3em;
    height: 50vh;
    width: 100vw;
  }

  .banner-heading-text-css-1 {
    font-size: 30px;
    color: #fff;
    font-family: 'Playfair Display', serif;
  }

  .banner-heading-text-css-2 {
    font-size: 1rem !important;
    color: #fff;
    font-weight: 500;
  }

  .banner-position-css {
    position: relative;
    top: 30%;
  }

  /* =======================================SECOND-SECTION================================================= */
  .text-justify {
    text-align: justify;
  }

  .second-section-heading-1 {
    font-size: 25px;
    color: #fffce1;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
  }

  .second-section-heading-2 {
    font-size: 0.8rem !important;
    color: #ffffff;
    font-weight: 400;
  }

  .glass-section-css-styling {
    background-color: #0d0d0d;
    /* border-top-left-radius: 4em;
      border-top-right-radius: 4em; */
  }

  .glass-panel {
    /* color: #000000;
      background-color: rgb(219, 13, 13);
      border: 1px solid rgba(255, 255, 255, 0.361);
      width: 100%; */
    padding: 20px 32px 32px 32px;
    /* backdrop-filter: blur(10px); */

  }

  .glass-button {
    padding: 14px 22px;
    text-decoration: none;
    border-radius: 15px 0px 15px 0px;
    background: linear-gradient(to left, #fffce1, #ffffff);
    color: rgb(0, 0, 0);
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Heebo', sans-serif;
    font-size: 0.8rem !important;
  }

  .glass-button:hover {
    background: linear-gradient(to left, #ffffff, #fffce1);
    border-radius: 0px 15px 0px 15px;
  }

  /* =======================================THIRD-SECTION================================================= */

  .third-section-title-text-css-1 {
    font-size: 25px;
    color: #fffce1;
    font-family: 'Playfair Display', serif;
    text-transform: uppercase;
  }

  /* .border-bottom-black {
      border-bottom: 0.2em solid #fffce1;
    } */

  .third-section-heading-1 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  .card-image-css-styling {
    border-radius: 0.8em;
    width: 200px;
    height: 150px;
  }

  /* =======================================FOURTH-SECTION================================================= */
  .marquee-full-bg {
    background: #000000;
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to left, rgb(0, 60, 112), hsl(234, 100%, 8%)); */
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to left, #000749, #000428); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background-color: #33383e; */
    background: linear-gradient(to left, #000000, #000000);
  }

  .marquee-card-styling {
    width: 150px;
    text-align: center;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 0.3em;
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    mask-image: linear-gradient(135deg, #000 0, #000 22%, rgba(0, 0, 0, 0) 160%);
    -webkit-mask-image: linear-gradient(135deg, #000 0, #000 22%, rgba(0, 0, 0, 0) 160%);
    padding: 10px;
    margin: 0px 10px 0px 10px;
  }

  .marquee-section-heading-1 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;
  }

  .tech-icon-css-styling {
    width: 50px;
    height: 50px;
  }

  /* =======================================FIFTH-SECTION================================================= */
  .curve-card-heading-1 {
    font-size: 15px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  .curve-card-para-1 {
    font-size: 12px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;

  }

  .portfolioDisc {
    width: 100%;
    height: 0px;
    padding-top: 0px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top: 300px;
    position: absolute;
    font-size: 15px;
    /* line-height: 18px; */
    text-align: center;
    font-family: 'Heebo', sans-serif;
    color: #ffffff;
    align-items: center !important;

  }


  .port-1 {
    background-image: url("../../Assests/Images/Services/construction.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-2 {
    background-image: url("../../Assests/Images/Services/Healthcare.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-3 {
    background-image: url("../../Assests/Images/Services/Bookingticket.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-4 {
    background-image: url("../../Assests/Images/Services/Hotel.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-5 {
    background-image: url("../../Assests/Images/Services/GPStracking.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 200px;
    position: relative;
    transition: transform .6s;
    overflow: hidden !important;
  }

  .port-1:hover,
  .port-2:hover,
  .port-3:hover,
  .port-4:hover,
  .port-5:hover {
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
    overflow: hidden !important;
  }

  .port-1:hover .portfolioDisc {
    margin-top: 0px;
    height: 200px;
    padding-top: 10%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-2:hover .portfolioDisc {
    margin-top: 0px;
    height: 200px;
    padding-top: 10%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-3:hover .portfolioDisc {
    margin-top: 0px;
    height: 200px;
    padding-top: 10%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-4:hover .portfolioDisc {
    margin-top: 0px;
    height: 200px;
    padding-top: 10%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  .port-5:hover .portfolioDisc {
    margin-top: 0px;
    height: 200px;
    padding-top: 10%;
    /* background-image: linear-gradient(150.97deg, #00054786 8.01%, #000b4066 40.15%, #0500505f 56.92%); */
    background-image: linear-gradient(150.97deg, rgba(36, 38, 41, 0.628) 8.01%, rgba(27, 29, 32, 0.582) 40.15%, #16181b74 56.92%);

    /* opacity: .5; */
    -webkit-transition: all .5s;
    transition: all .5s;
    pointer-events: none;
    align-items: center !important;
    /* background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000)); */
    overflow: hidden !important;

  }

  /* .clippath-css-styling {
   
    clip-path: url("#myCurve");
    overflow: hidden !important;

  }

  .clippath-css-styling-2 {
    clip-path: url("#myCurvetwo");
  } */




  /* =======================================SIXTH-SECTION================================================= */
  .over-scroll-1 {
    overflow-x: scroll;
  }

  .over-scroll-2 {
    width: 900px;
  }

  /* #tabsSection {
    background-image: linear-gradient(#ffff 45%, #1F252B 10%);
  } */

  .line-height-tabs {
    z-index: 9999 !important;
  }

  #tabsSection .tab-content {
    position: relative;
    top: -45px;
    background-color: #000000;
    z-index: -999;
  }

  .sixth-section-text-css-2 {
    font-size: 1.3rem !important;
    color: #ffffff;
    font-weight: 500;
  }

  #tabsSection .nav-pills .nav-link.active,
  #tabsSection .nav-pills .show>.nav-link {
    color: #fffce1;
    background-color: transparent;
  }

  .tabs-inner-heading-css {
    font-size: 1em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: rgb(255, 255, 255);
  }

  .tabs-inner-para-css {
    font-size: 0.9rem !important;
    color: #ffffff;
    /* font-family: 'Heebo', sans-serif; */
  }

  .tabs-heading-css {
    font-size: 1em;
    font-weight: 500;
    font-family: 'Heebo', sans-serif;
    color: rgb(111, 111, 111);
    height: 100px;
  }

  #tabsSection .nav-pills .nav-link.active .tabs-heading-css {
    color: #fffce1;
  }

  .tabs-icon-div-styling-css {
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    padding: 1em;
    border: 3px solid #fff;
    width: 60px;
    height: 60px;
  }

  .tabs-icon-styling-css {
    font-size: 1.5em;
    color: #fffce1;
  }

  #tabsSection .nav-pills .nav-link.active .tabs-icon-div-styling-css {
    background-color: #ffffff;
    border: 4px solid #9c9c9c;
    border-radius: 50%;
    padding: 1em;
  }

  #tabsSection .nav-pills .nav-link.active .tabs-icon-styling-css {
    font-size: 1.5em;
    color: #000000;
  }

  .tabs-content-ul li {
    color: #ffff;
    list-style: none;
    margin-bottom: 10px;
  }

  .tabs-content-ul li::before {
    content: "\2B9E";
    margin-right: 6px;
  }

  .tabs-content-image-css {
    width: 50%;
    height: 50%s;
  }

  /* =======================================OUR-TEAM-SECTION================================================= */


  #ourteam_testing .modal-content {
    background-color: #2E2E2E;
    color: #ffffff;
  }

  #ourteam_testing .our-team-card-image-css-styling {
    width: 50%;
    height: 100px;
  }

  #ourteam_testing .our-team-section-heading-1 {
    font-size: 26px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;

  }

  #ourteam_testing .our-team-section-heading-2 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  #ourteam_testing .inner-card-design-css {
    background-color: #373636;
    /* clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%); */
  }


  #ourteam_testing .inner-card-design-css-2 {
    background-color: #000000;
    border: 0.5em double #373636;
  }

  /* =======================================FOOTER-SECTION================================================= */
  #pricingModal .modal-content {
    background-color: #2E2E2E;
    color: #ffffff;
  }

  #pricingModal .footer-card-image-css-styling {
    width: 80%;
    height: 150px;
  }

  #pricingModal .footer-card-section-heading-1 {
    font-size: 20px;
    color: #ffffff;
    font-family: 'Playfair Display', serif;

  }

  #pricingModal .footer-card-section-heading-2 {
    font-size: 18px;
    color: #ffffff;
    font-family: 'Heebo', sans-serif;
  }

  #pricingModal .inner-card-design-css {
    background-color: #0d0d0d;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
  }


  #pricingModal .inner-card-design-css-2 {
    background-color: #373636;
  }

  #pricingModal .footer-heading-height-css {
    height: 70px;

  }


  ul {
    margin: 0px;
    padding: 0px;
  }

  .footer-section {
    background: linear-gradient(to left, #000000, #000000);
    color: #ffffff;
    position: relative;
  }

  .footer-cta {
    border-top: 1px solid #373636;
  }

  .single-cta i {
    color: #ff5e14;
    font-size: 30px;
    float: left;
    margin-top: 8px;
  }

  .cta-text {
    padding-left: 15px;
    display: inline-block;
  }

  .cta-text h4 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  .cta-text span {
    color: #ffffff;
    font-size: 15px;
  }

  .footer-content {
    position: relative;
    z-index: 2;
  }

  .footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
  }

  .footer-logo {
    margin-bottom: 30px;
  }

  .footer-logo img {
    max-width: 50px;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #ffffff;
    line-height: 28px;
  }

  .footer-social-icon span {
    color: #ffffff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
  }

  .footer-social-icon a {
    color: #ffffff;
    font-size: 16px;
    margin-right: 15px;
  }

  .footer-social-icon i {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 38px;
    border-radius: 50%;
  }

  .facebook-bg {
    background: #3B5998;
  }

  .twitter-bg {
    background: #55ACEE;
  }

  .google-bg {
    background: #DD4B39;
  }

  .footer-widget-heading h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
  }

  .footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #ff5e14;
  }

  .footer-widget ul li {
    /* display: inline-block;
    float: left; */
    width: 100%;
    margin-bottom: 12px;
    list-style: none;
  }

  .footer-widget ul li a:hover {
    color: #ff5e14;
  }

  .footer-widget ul li a {
    color: #ffffff;
    text-transform: capitalize;
  }

  .subscribe-form {
    position: relative;
    overflow: hidden;
  }

  .subscribe-form .form-control {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
    /* margin-bottom: 0.5em; */
  }

  .subscribe-form .form-control:focus {
    background: #2E2E2E;
    border: 1px solid #2E2E2E;
    color: #fff;
    outline: none;
    box-shadow: none;
  }

  /* .subscribe-form button {
    position: absolute;
    right: 0;
    background: #ff5e14;
    padding: 13px 20px;
    border: 1px solid #ff5e14;
    top: 0;
  }

  .subscribe-form button i {
    color: #ffffff;
    font-size: 22px;
    transform: rotate(-6deg);
  } */

  .copyright-area {
    border-top: 1px solid #373636;
    background: linear-gradient(to left, #0d0d0d, #0d0d0d);


    /* background: #202020; */
    padding: 25px 0;
  }

  .copyright-text p {
    margin: 0;
    font-size: 14px;
    color: #878787;
  }

  .copyright-text p a {
    color: #ff5e14;
  }

  .footer-menu li {
    display: inline-block;
    margin-left: 20px;
  }

  .footer-menu li:hover a {
    color: #ff5e14;
  }

  .footer-menu li a {
    font-size: 14px;
    color: #ffffff;
  }

}





/* ======================================TESTING================================= */